<template>
  <div class="pages">
    <div class="inside-nav">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        :highlight-current="true"
        accordion
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>

    <!-- 表格 -->
    <div class="task_box">
      <!-- 数据表格 -->
      <div class="table_boxs">
        <el-table
          ref="multipleTable"
          :data="tableList"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
        >
          <el-table-column label="序号" align="center" width="80">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="device.name"
            label="设备名称"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="device.equip_no"
            label="设备编号"
            min-width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="device.type_text"
            label="用电类型"
            min-width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="device.place"
            label="设备位置"
            min-width="150"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="type_text"
            label="预警类型"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="level_text"
            label="预警等级"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="createtime"
            label="预警时间"
            min-width="200"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="status_text"
            label="当前状态"
            width="120"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="120"
          >
            <template slot-scope="scope">
              <div class="handle_box">
                <div
                  class="handle_btn edit"
                  v-if="scope.row.status == 1"
                  @click="affirmHandle(scope.row.id)"
                >
                  确认处理
                </div>
                <div class="handle_btn" v-if="scope.row.status == 2">
                  已处理
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="queryInfo.page"
          :page-size="queryInfo.limit"
          @current-change="handleCurrentChange"
          next-text="下一页"
          prev-text="上一页"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 副 菜单
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      // 表格列表
      tableList: [],
      // 总数据条数
      total: 0,
      // 表格翻页
      queryInfo: {
        organize_id: "",
        page: 1,
        limit: 10,
      },
    };
  },
  mounted() {
    // 获取左侧二级菜单的数据
    this.getTreeTitleList();
    // 获取表格列表数据
    this.getTableList();
  },
  methods: {
    // 获取左侧二级菜单的数据
    async getTreeTitleList() {
      const res = await this.$http.get("Organize");
      console.log(res);
      if (res.code !== 1) return this.$message.error("获取组织列表失败！");
      // this.$message.success('获取设备列表成功！')
      this.treeData = res.data;
    },

    // 获取 表格列表数据
    async getTableList() {
      const res = await this.$http.post("abnormal", this.queryInfo);
      if (res.code !== 1) return this.$message.error("获取设备列表失败！");
      // this.$message.success('获取设备列表成功！')
      this.tableList = res.data.list;
      this.total = res.data.total;
    },

    // 确认处理操作数据
    async affirmHandle(id) {
      this.$confirm("此预警是否已确认处理完成？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("abnormal/handle", {
            id: id,
            status: 2,
          });
          if (res.code !== 1) return this.$message.error("确认处理失败！");
          this.$message.success("确认处理成功！");
          // 处理成功后要更新表格数据
          this.getTableList();
        })
        .catch(() => {
          this.$message.info("已取消处理此预警");
        });
    },

    // 分页切换 获取列表数据
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getTableList();
    },

    // 左侧二级菜单点击事件
    handleNodeClick(data) {
      // console.log(data.id);
      if (data.id) {
        this.queryInfo.organize_id = data.id;
        // 获取 点击筛选 表格数据
        this.getTableList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */
.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}

// 表格
.task_box {
  width: calc(100% - 220px);
  margin-top: 40px;
}
.table_boxs::v-deep {
  position: relative;

  // tab表格样式
  .el-table {
    width: 100%;
  }

  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #32477d;
  }
  .el-table tr {
    background-color: #121d3c;
  }
  .el-table {
    color: #ffffff;
  }
  .el-table thead {
    color: #68d8fe;
  }
  .el-tabs__content {
    overflow: unset !important;
  }
  .el-table-column--selection {
    padding: 16px 10px !important;
  }
  .el-table .has-gutter .el-table__cell,
  .el-table th.el-table__cell {
    background-color: #213058;
  }
  .el-table .el-table__cell {
    // padding: 16px 0;
    border: 0;
  }
  .el-table::before,
  .el-table__fixed-right::before {
    height: 0;
  }
  tr.el-table__row--striped td.el-table__cell {
    background-color: #213058;
  }
  .handle_box {
    display: flex;
    align-items: center;
    justify-content: center;
    .handle_btn {
      height: 28px;
      line-height: 28px;
      width: 70px;
      border-radius: 5px;
      font-size: 13px;
      cursor: pointer;
      text-align: center;
      background-color: #cccccc;
    }
    .edit {
      background-color: #61cdf1;
    }
  }
  // 分页
  .el-pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-pager li {
      font-size: 12px;
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      padding: 0;
      height: 32px;
      line-height: 32px;
      min-width: 32px;
      margin: 0 7px;
    }
    .btn-next,
    .btn-prev {
      border: 1px solid #61cdf1;
      font-weight: 500;
      color: #ffffff;
      background-color: #1b284d;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
    }
    .btn-next span,
    .btn-prev span {
      font-size: 12px;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
